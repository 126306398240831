import { theme } from '@chakra-ui/core';

export default {
  ...theme,
  colors: { ...theme.colors, primary: '#C00000', text: '#27326C' },
  fonts: {
    body: 'LatoRegular',
    heading: 'LatoBold',
  },
};
