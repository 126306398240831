import { Alert, AlertIcon, Box, Button, Heading } from '@chakra-ui/core';
import React, { useCallback, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Checkbox from '../../common/components/Checkbox';
import {
  addAddress,
  getAddAddress,
  getHasMaskedBillingAddress,
  getIsOobRequired,
} from '../../slice';
import AddressFields from './AddressFields';

const AddressForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, data, error } = useSelector(getAddAddress);
  const isOobRequired = useSelector(getIsOobRequired);

  const onSubmit = useCallback(
    (values) => {
      dispatch(addAddress(values));
    },
    [dispatch]
  );

  useEffect(() => {
    if (data) {
      history.push(isOobRequired ? '/oob' : '/confirm-enrollment');
    }
    return () => {};
  }, [data, history, isOobRequired]);

  const hasMaskedBillingAddress = useSelector(getHasMaskedBillingAddress);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        setAsShippingAddress: true,
      }}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Box width={['100%', '100%', '100%', '50%']}>
            {error && (
              <Alert status="error" mb={8}>
                <AlertIcon />
                {error}
              </Alert>
            )}
            {!hasMaskedBillingAddress && (
              <>
                <Heading fontSize="md" my={8}>
                  Billing address
                </Heading>
                <AddressFields keyName="billingAddress" />
                <Box my={8}>
                  <Field
                    name="setAsShippingAddress"
                    label="Set as shipping address"
                    component={Checkbox}
                  />
                </Box>
              </>
            )}
            {(!values.setAsShippingAddress || hasMaskedBillingAddress) && (
              <>
                <Heading fontSize="md" my={8}>
                  Shipping address
                </Heading>
                <AddressFields keyName="shippingAddress" />
              </>
            )}
          </Box>
          <Box mt={8}>
            <Button
              size="lg"
              type="submit"
              variantColor="purple"
              isLoading={isLoading}
            >
              Continue
            </Button>
          </Box>
        </form>
      )}
    />
  );
};

export default AddressForm;
