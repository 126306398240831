import { CSSReset, ThemeProvider } from '@chakra-ui/core';
import React, { Fragment, useState } from 'react';
import LoadScript from 'react-load-script';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import Global from './app/components/Global';
import Header from './app/components/Header';
import theme from './app/theme';
import store from './store';

const Root = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  return (
    <>
      <LoadScript
        url={process.env.REACT_APP_SRC_SDK_URL}
        onCreate={() => {}}
        onError={(error) => {
          setError(error);
        }}
        onLoad={() => setIsLoading(false)}
      />
      {!isLoading && !error && (
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CSSReset />
            <Global />
            <Router>
              <Fragment>
                <Header />
                <App />
              </Fragment>
            </Router>
          </ThemeProvider>
        </Provider>
      )}
    </>
  );
};

export default Root;
