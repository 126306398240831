import { FormControl, FormLabel, Input } from '@chakra-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';

import FormError from './FormError';

const CustomInput = ({ input = {}, ...props }) => <Input {...props} />;

const MaskedTextInput = ({
  input,
  meta,
  label,
  customFormat,
  customMask = '',
  ...props
}) => {
  return (
    <FormControl mb={4}>
      {label && <FormLabel htmlFor={input.name}>{label}</FormLabel>}
      <NumberFormat
        customInput={CustomInput}
        meta={meta}
        {...props}
        value={input.value}
        name={input.name}
        onValueChange={({ floatValue, value, formattedValue }) => {
          input.onChange(value);
        }}
        focusBorderColor="purple.500"
        format={customFormat}
        mask={customMask}
      />
      <FormError meta={meta} />
    </FormControl>
  );
};

export default MaskedTextInput;
