import { Text } from '@chakra-ui/core';
import React, { Fragment } from 'react';

const FormError = ({ meta = {} }) => (
  <Fragment>
    {(meta.touched || meta.submitFailed) && (meta.error || meta.submitError) && (
      <Text color="red.500" fontSize="sm" mt={1}>
        {meta.error || meta.submitError[0]}
      </Text>
    )}
  </Fragment>
);

export default FormError;
