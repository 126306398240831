import {
  Select as ChakraSelect,
  FormControl,
  FormLabel,
} from '@chakra-ui/core';
import React from 'react';

import FormError from './FormError';

const Select = ({ input, label, meta, options = [], ...props }) => (
  <FormControl mb={4}>
    <FormLabel htmlFor={input.name}>{label}</FormLabel>
    <ChakraSelect focusBorderColor="purple.500" {...props} {...input}>
      <option value=""></option>
      {options.map((option) => (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </ChakraSelect>
    <FormError meta={meta} />
  </FormControl>
);

export default Select;
