import { Checkbox as ChakraCheckbox } from '@chakra-ui/core';
import React from 'react';

const Checkbox = ({ input = {}, label, meta, ...props }) => (
  <ChakraCheckbox
    variantColor="purple"
    {...props}
    {...input}
    defaultIsChecked={input.value}
  >
    {label}
  </ChakraCheckbox>
);

export default Checkbox;
