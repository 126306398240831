import { Flex, Spinner, Box } from '@chakra-ui/core';
import React from 'react';

const Loader = () => (
  <Flex width="100%" textAlign="center" flexDirection="column" my={16}>
    <Box>
      <Spinner size="xl" mb={4} />
    </Box>
  </Flex>
);

export default Loader;
