import { FormControl, FormLabel, Input } from '@chakra-ui/core';
import React from 'react';

import FormError from './FormError';

const TextField = ({ input, meta, label, ...props }) => (
  <FormControl mb={4}>
    <FormLabel htmlFor={input.name}>{label}</FormLabel>
    <Input
      focusBorderColor="purple.500"
      type="text"
      {...input}
      {...meta}
      {...props}
    />
    <FormError meta={meta} />
  </FormControl>
);

export default TextField;
