import { css, Global as EmotionGlobal } from '@emotion/core';
import React from 'react';

const Global = () => {
  return (
    <EmotionGlobal
      styles={css`
        @font-face {
          font-family: 'LatoRegular';
          src: url('/fonts/Lato-Regular.woff2') format('woff2'),
            url('/fonts/Lat-Regular.woff') format('woff');
        }

        @font-face {
          font-family: 'LatoBold';
          src: url('/fonts/Lato-Bold.woff2') format('woff2'),
            url('/fonts/Lato-Bold.woff') format('woff');
        }
      `}
    />
  );
};

export default Global;
